import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../../components/Layout";
import {IHeroSection} from "../../components/templates/hero/hero-section.interface";
import {IFeatureSection} from "../../components/templates/features-z-pattern/feature-section.interface";
import {ISpecialOfferSection} from "../../components/templates/special-offer/special-offer.interface";
import {IHelpSection} from "../../components/templates/help/help-section.interface";
import Hero from "../../components/templates/hero/Hero";
import FeaturesZPattern from "../../components/templates/features-z-pattern/FeaturesZPattern";
import SpecialOffer from "../../components/templates/special-offer/SpecialOffer";
import Help from "../../components/templates/help/Help";
import FeatureItem from "../../components/device/FeatureItem";
import {SiteMetadata} from "../../types/site-metadata.type";
import {SiteMetadataConfigProps} from "../../types/metadata-config-props.type";
import DeviceSEO from "../../components/device/DeviceSEO";
import {PageContextType} from "../../types/page-context.type";
import Video, {IVideoSection} from '../../components/video/Video';

type HowItWorksProps = {
  strapiDeviceHowItWork: {
    siteMetadata: SiteMetadata,
    hero: IHeroSection,
    properties: IFeatureSection[],
    specialOffer: ISpecialOfferSection,
    help: IHelpSection,
    video: IVideoSection,
  }
} & SiteMetadataConfigProps

const HowItWorks = ({data: {strapiDeviceHowItWork: {help, specialOffer, hero, video, properties, siteMetadata}}, pageContext}: PageProps<HowItWorksProps, PageContextType>) => {
  const propertiesWithoutLast = [properties[0], properties[1]];

  return(
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <Hero title={hero.title} image={hero.image}/>
      <FeaturesZPattern features={propertiesWithoutLast} id={'features-section'} showSteps>
        <FeatureItem index={2} title={properties[2].title} description={properties[2].description.data.description} image={properties[2].image} />
      </FeaturesZPattern>
      <Video video={video} />
      <SpecialOffer specialOffer={specialOffer} />
      <Help helpSection={help} />
    </Layout>
  )
}

export default HowItWorks;

export const Head = (props: HeadProps<HowItWorksProps>) => {
  const path = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const siteMetadata = props.data.strapiDeviceHowItWork.siteMetadata;
  return (
    <DeviceSEO siteUrl={siteUrl} path={path} siteMetadata={siteMetadata} />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDeviceHowItWork {
      siteMetadata {
        crumbLabel
        title
        description
      }
      hero {
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
      properties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      video {
        description {
          data {
            description
          }
        }
        title
        youtubeUrl
        showSection
      }
      specialOffer {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          url
          label
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      help {
        description
        email
        title
        number
      }
    }
  }
`
