import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import TextFormatter from "../../utils/TextFormatter";
import MobileAppLinks from "../shared/mobile-app-links/MobileAppLinks";

type FeatureProps = {
  index: number,
  title: string,
  description: string,
  image: GatsbyImageCustomType,
}

const FeatureItem = ({index, title, image, description}: FeatureProps) => {
  const formattedDescription = TextFormatter.concatOrphans(description) || description;
  const even = index % 2 === 0;

  return (
    <div className={'flex flex-col justify-center items-center bg-white even:bg-tertiary-40 w-full'}>
      <div className={`flex flex-col items-center ${even ? 'lg:flex-row' : 'lg:flex-row-reverse'} px-12 md:px-36 py-20 md:py-28 gap-y-11 gap-x-18 xl:gap-x-24 max-w-[1520px]`}>
        <div className={'flex flex-col lg:w-1/2'}>
          <div className={'flex flex-row gap-6 mb-10 items-center'}>
            <div className={'flex shrink-0 justify-center items-center border-2 border-secondary-60 w-24 h-24 rounded-full'}>
              <p className={`text-5xl font-head ${even ? 'text-black' : 'text-white'}`}>{index + 1}</p>
            </div>
            <div className={'flex flex-col gap-1.5 text-start'}>
              <p className={'uppercase text-primary-60 text-xs lg:text-sm text-semibold'}>Krok NR {index + 1}</p>
              <h2 className={`font-head text-bold text-xl lg:text-2xl xl:text-3xl ${even ? 'text-black' : 'text-white'}`}>{title}</h2>
            </div>
          </div>
          <div className={`description-container whitespace-pre-line lg:pr-9 text-lg ${even ? 'text-neutrals-40' : 'text-neutrals-80'}`} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>
        <div className={'relative flex lg:w-fit '}>
          <GatsbyImage alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData} />
          <MobileAppLinks className={'flex flex-col xs:flex-row gap-y-2 sm:gap-3 justify-center items-center absolute bottom-0 translate-x-1/2 bottom-[10%] xs:bottom-1/4 right-1/2'}/>
        </div>
      </div>
    </div>
  )
}

export default FeatureItem;
